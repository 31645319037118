import React, {useState} from "react";
import emailjs from '@emailjs/browser'
import {useForm} from 'react-hook-form';


const ContactForm = () => {

  const [disabled, setDisabled] = useState(false);
  const [alertInfo, setAlertInfo] = useState({display: false, message: '', type: '',});
  const {register, handleSubmit, reset, formState: { errors }} = useForm();


  console.log(disabled)

  // Shows alert message for form submission feedback
  const toggleAlert = (message, type) => {
    setAlertInfo({ display: true, message, type });

// Hide alert after 20 seconds
   setTimeout(() => {
     setAlertInfo({ display: false, message: '', type: '' });
       }, 20000);
       };

  // Function called on submit that uses emailjs to send email of valid contact form
      const onSend = async (data) => {
        // Destrcture data object
        const { name, email, phone, message } = data;
        
        console.log('Name: ', name);
        console.log('Email: ', email);
        console.log('Phone: ', phone);
        console.log('Message: ', message);

        try {
          // Disable form while processing submission
          setDisabled(true);

          // Define template params
          const templateParams = {
            name,
            email,
            phone,
            message
          };
          // Use emailjs to email contact form data
          await emailjs.send(
            process.env.REACT_APP_serviceID,
            process.env.REACT_APP_templateID,
            templateParams,
            process.env.REACT_APP_publicKey
          );
         // Display success alert
          toggleAlert('Form submission was successful!', 'success');
          } catch (e) {
              console.error(e);
              // Display error alert
              toggleAlert('Uh oh. Something went wrong.', 'danger');
          } finally {
              // Re-enable form submission
              setDisabled(false);
              // Reset contact form fields after submission
              reset();
           }
      };

      
  return (
    <>
    
           <div className="form-box-wrapper">
            
      <form className="form-box" onSubmit={handleSubmit(onSend)} noValidate>
      <div className="required">
      <span className="asterisk">*</span><label>Required field</label> 
      </div>
        
        <div className="name-wrapper">      
          <div className="name">
          <div className="asterisk-box">
            <label>Name</label><span className="asterisk">*</span>
          </div>
            <input type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Please enter your name' },
                        maxLength: {
                          value: 30,
                          message: 'Please use 30 characters or less'
                        }
                      })}
                      className='input'
                      ></input>
                      {errors.name && <span className='errorMessage'>{errors.name.message}</span>}
          </div>
        </div>

          {/* <label>Address</label>
          <input type="text" name="user_street" placeholder='Street' />
          <input type="text" name="user_city" placeholder='City' />
          <input type="number" name="user_zipcode" placeholder='Zip Code' /> */}
      
        <div className="email-phone-box">
          <div className="email-phone">
            <div className="asterisk-box">
            <label>Email</label> <span className="asterisk">*</span>
            </div>
            <input   
                    type='email'
                    name='email'
                    {...register('email', {
                        required: true,
                        // pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                    })}
                    className='input'
                    ></input>
                      {errors.email && (
                      <span className='errorMessage'>Please enter a valid email address</span>
                    )}
        </div>
      

        <div className="email-phone">
        <div className="asterisk-box">
          <label>Phone Number</label><span className="asterisk">*</span>
        </div>
          <input  type='tel'
                    name='phone'
                    {...register('phone', {
                        required: true,
                    })}
                    className='input'
                    ></input>
                      {errors.phone && (
                      <span className='errorMessage'>Please enter a valid phone number</span>
                    )}
        </div>
        </div>

        <div className="message-wrapper">
          <div className="message">
            
          <div className="asterisk-box">
            <label>Please describe your project in a few sentences</label><span className="asterisk">*</span>
          </div>
            <textarea 
            rows={3}
            name='message'
            {...register('message', {
              required: true
            })}
            className='input'
            ></textarea>
            {errors.phone && (
                      <span className='errorMessage'>Please enter a description of your project</span>
                    )}
          </div>
        </div>

            {/* <label>Do you have plans drawn for this project?</label>
            <input type="radio" id="Yes" name="Yes" value="Yes" />
            <label for="Yes">Yes</label>
            <input type="radio" id="No1" name="No1" value="No1" />
            <label for="No1">No, but I would like you to draw plans</label>
            <input type="radio" id="No2" name="No2" value="No2" />
            <label for="No2">No, this project doesn't need blueprints</label> */}

            {/* <label>How much would you like to invest in your project?</label>
            <input type="text" name="invest" /> */}

            {/* <label>When would you like this remodel to be completed?</label>
            <input type="text" name="timeframe" /> */}

            {/* <label>Please upload photos and blueprints if you have them</label>
            <input type="file" name="fileupload" accept="image/png, image/jpeg, .pdf" /> */}

        <button className="button-5 send_button">Send</button>     
      {alertInfo.display && (
        <div className={`alert alert-${alertInfo.type}`} role='alert'>
          {alertInfo.message}
          <button
            type='button'
            className='btn-close'
            data-bs-dismiss='alert'
            aria-label='Close'
            onClick={() =>setAlertInfo({ display: false, message: '', type: '' })
            } // Clear the alert when close button is clicked
          >X</button>
        </div>
      )}
         </form>
      </div>
      
    </>
  );
};

export default ContactForm;
