import React, {useEffect} from "react";
import { useNavigate} from "react-router-dom";
import kitchen from '../images/Glenan-Kitchen-Best.jpg'
import bath from '../images/Glenan-Bath-Best.jpg'
import office from '../images/home-office.jpg'
import "../style/ResidentialServices.css";

const ResidentialServices = () => {
  
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  return (

      <>
      <div className="top-intro-container">
        <div className="top-intro-box"></div>
        <div className="top-intro">
        <h1>Residential Construction Services</h1>
        
            
            <div className="top-intro-button-box">
            <button className="button-5" onClick={() => {navigate("/about"); window.scrollTo(0, 0);}}>About Us</button>
            <button className="button-5" onClick={() => {navigate("/contact"); window.scrollTo(0, 0);}}>Request an Estimate</button>
            </div>
        </div>
      </div>

      {/* clean these up by storing as a array in a separate file and mapping through the array */}

      <div className="service_box_container">
        <div className="service-box">
                <div className="img-box">
                    <img
                        src={kitchen}
                        alt="Kitchen Renovation"
                        width="250"
                        height="150"
                    ></img>
                </div>
                <div className="list-box">
                    <h2>Kitchen Remodeling & Renovations</h2>

                    <ul className="lists">
                        <li>Cabinets & Countertops</li>
                        <li>Kitchen Floor Replacement</li>
                        <li>Appliance Upgrades</li>
                        <li>Fixture Upgrades</li>
                        
                    </ul>
                        
                    <div className="button-box">
                        <button className="button-5" onClick={() => {navigate("/contact"); window.scrollTo(0, 0);}}>Request an Estimate</button>
                    </div>
                </div>
            </div>

            <div className="service-box">
                <div className="img-box">
                    <img
                        src={bath}
                        alt="Bathroom Renovation"
                        width="250"
                        height="150"
                    ></img>
                </div>
                <div className="list-box">
                    <h2>Bathroom Remodeling & Renovations</h2>

                    <ul className="lists">
                        <li>Vanity Replacement</li>
                        <li>Bathroom Floor Replacement</li>
                        <li>Shower/Tub Installations</li>
                        <li>Fixture Upgrades</li>
                    </ul>
                        
                    <div className="button-box">
                        <button className="button-5" onClick={() => {navigate("/contact"); window.scrollTo(0, 0);}}>Request an Estimate</button>
                    </div>
                </div>
            </div>

            <div className="service-box">
                <div className="img-box">
                    <img
                        src={office}
                        alt="Office Renovation"
                        width="250"
                        height="150"
                    ></img>
                </div>
                <div className="list-box">
                    <h2>Home Remodeling & Renovations</h2>

                    <ul className="lists">
                        <li>Home Office Improvements</li>
                        <li>Bedroom Remodeling</li>
                        <li>Basement Renovation</li>
                        <li>Fixture Upgrades</li>
                        
                    </ul>
                        
                    <div className="button-box">
                        <button className="button-5" onClick={() => {navigate("/contact"); window.scrollTo(0, 0);}}>Request an Estimate</button>
                    </div>
                </div>
            </div>

            


        </div>
      
    </>
  );
};

export default ResidentialServices;
