import React, {useEffect} from "react";
import { useNavigate} from "react-router-dom";
import "../style/CommercialServices.css";
import kitchen from '../images/Glenan-Kitchen-Best.jpg'


const CommercialServices = () => {
  
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <h1 className="page-title">Commercial Construction Services</h1>

    {/* clean these up by storing as a array in a separate file and mapping through the array */}

    <div className="service_box_container">
      <div className="service-box">
              <div className="img-box">
                  <img
                      src={kitchen}
                      alt="Office"
                      width="250"
                      height="150"
                  ></img>
              </div>
              <div className="list-box">
                  <h2>Exterior Improvements</h2>

                  <ul className="lists">
                      <li>Metal Railing and Guardrail Installation</li>
                      <li>Exterior Storefront Replacement</li>
                      <li>Facade Renovation</li>
                      <li>Outdoor Amenities</li>
                      
                  </ul>
                      
                  <div className="button-box">
                      <button className="button-5" onClick={() => navigate("/contact")}>Request an Estimate</button>
                  </div>
              </div>
          </div>

          <div className="service-box">
              <div className="img-box">
                  <img
                      src={kitchen}
                      alt="Office"
                      width="250"
                      height="150"
                  ></img>
              </div>
              <div className="list-box">
                  <h2>Tenant Upfits, Remodeling, & Rennovations</h2>

                  <ul className="lists">
                      <li>Vanity Replacement</li>
                      <li>Bathroom Floor Replacement</li>
                      <li>Shower/Tub Installations</li>
                      <li>Fixture Upgrades</li>
                  </ul>
                      
                  <div className="button-box">
                      <button className="button-5" onClick={() => navigate("/contact")}>Request an Estimate</button>
                  </div>
              </div>
          </div>

          <div className="service-box">
              <div className="img-box">
                  <img
                      src={kitchen}
                      alt="Office"
                      width="250"
                      height="150"
                  ></img>
              </div>
              <div className="list-box">
                  <h2>Office Upfits, Remodeling, & Renovations</h2>

                  <ul className="lists">
                      <li>Cabinets & Countertops</li>
                      <li>Kitchen Floor Replacement</li>
                      <li>Appliance Upgrades</li>
                      <li>Fixture Upgrades</li>
                      
                  </ul>
                      
                  <div className="button-box">
                      <button className="button-5" onClick={() => navigate("/contact")}>Request an Estimate</button>
                  </div>
              </div>
          </div>

          


      </div>
    
  </>
  );
};

export default CommercialServices;
