import React, {useEffect} from "react";
import { useNavigate} from "react-router-dom";

import "../style/Contact.css";
import ContactForm from "./ContactForm";


const Contact = () => {

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  

  return (
    <>
    <div className="top-intro-container">
      <div className="top-intro-box contact-about"></div>
            <div className="top-intro">
            <h1>Schedule a Meeting with Our Estimator</h1>
            <br></br>
            <h3 className="contact-h3">Please fill out the form below so we can learn more about your project. We will reach out to you as soon as possible.</h3>
            
              
              <div className="top-intro-button-box">
                <button className="button-5" onClick={() => {navigate("/about"); window.scrollTo(0, 0);}}>About Us</button>
                <button className="button-5" onClick={() => {navigate("/photo-gallery"); window.scrollTo(0, 0);}}>Photo Gallery</button>
              </div>
            </div>
      </div>
     

    <div className="contact-form">

      <div className="sub-box">
        <h2>Nebula Construction</h2>
        <p>Phone: (804) 893-0217</p>
        <p>Email: buildwithnebula@gmail.com</p>
      </div>
      

      <ContactForm />
    </div>
    </>
  );
};

export default Contact;