import React, {useEffect} from "react";
import { useNavigate} from "react-router-dom";
import "../style/Services.css";
import kitchen from '../images/Glenan-Kitchen-Best.jpg'
import balcony from '../images/Balcony.jpg'

const Services = () => {
  
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <div className="top-intro-container">
      <div className="top-intro-box services-intro"></div>
      <div className="top-intro">
        <h1>Residential & Commercial Construction Services</h1>
        
        
        <div className="top-intro-button-box">
          <button className="button-5" onClick={() => {navigate("/about"); window.scrollTo(0, 0);}}>About Us</button>
          <button className="button-5" onClick={() => {navigate("/contact"); window.scrollTo(0, 0);}}>Request an Estimate</button>
        </div>
      </div>
      
    </div>
      
      <div className="services-box-wrapper-servpage">
        

            <div className="services-box">

              <div className="services-boxes">
              <div className="img-box">
                <img
                    src={kitchen}
                    alt="Kitchen"
                    width="250"
                    height="150"
                  ></img>
              </div>
                <h2 className="services-title">Residential Services</h2>

                <ul>
                    <li>Kitchen Remodeling & Renovations</li>
                    <li>Bathroom Remodeling & Renovations</li>
                    <li>Basement Remodeling & Renovations</li>
                    <li>Home Remodeling & Renovations</li>
                    <li>Additions & Garages</li>
                    <li>Home Inspection Report Tasks</li>
                    <li>Buyer/Seller Home Improvements</li>
                    <li>Home Maintenence, Repairs & Small Projects</li>
                  </ul>

                <div className="button-box">
                  <button className="button-5" onClick={() => {navigate("/residential-services"); window.scrollTo(0, 0);}}>Read More</button>
                </div>
                </div>

              <div className="services-boxes">
                <div className="img-box">
                  <img  
                    src={balcony}
                    alt="Balcony"
                    width="250"
                    height="150"
                  ></img>
                </div>
                <h2 className="services-title">Commercial Services</h2>

                  <ul>
                    <li>Tenant Upfits, Remodeling, & Renovations</li>
                    <li>Office Upfits, Remodeling, & Renovations</li>
                    <li>Rental Property Remodeling, & Renovations</li>
                    <li>Exterior Improvements</li>
                    <li>Warehouse Renovations</li>
                    <li>Facility Maintenance</li>
                    <li className="list-ghost">.</li>
                    <li className="list-ghost">.</li>
                  </ul>

                <div className="button-box">
                  <button className="button-5" onClick={() => {navigate("/services"); window.scrollTo(0, 0);}}>Read More</button>
                </div>
              </div>

            </div>
            </div>
    </>
  );
};

export default Services;
