import React from "react";
import "../style/Footer.css";

import { IoLogoFacebook, IoLogoInstagram, IoLogoLinkedin   } from "react-icons/io5";

const Footer = () => {
  

  return (
    <>
      <div className="footer-container">
        <div className="left-box">
          <h2 className="footer-h2">Nebula Construction</h2>
          <p>Virginia DPOR License #: 2705181784</p>
        </div>

        <div className="right-box">
          <p>Phone: (804) 893-0217</p>
          <p>Email: BuildWithNebula@gmail.com</p>
          <div className="social-logos-container">
            <IoLogoFacebook className="social-logos" onClick={() => window.open("https://www.facebook.com/nebulaconstruction/")} />
            <IoLogoInstagram className="social-logos" onClick={() => window.open("https://www.instagram.com/buildwithnebula/")}/>
            <IoLogoLinkedin className="social-logos" onClick={() => window.open("https://www.linkedin.com/company/nebula-construction")}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
