import React, {useEffect} from "react";
import { useNavigate} from "react-router-dom";
import "../style/About.css";
import kitchen from '../images/Glenan-Kitchen-Best.jpg'

const About = () => {
  
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
 

  return (
    <>
    <div className="top-intro-container">
      <div className="top-intro-box intro-about"></div>
      <div className="top-intro">
        <h1>About Nebula Construction</h1>
        <br></br>
        <div className="blurb">Transforming Spaces, Building Dreams - Nebula Construction is your trusted partner for residential and commercial construction projects. 
          With a commitment to quality craftsmanship, we turn visions into reality. 
        </div>
          <br></br>
        <div className="blurb-big">Let's build something exceptional together.</div>
        <div className="top-intro-button-box">
          <button className="button-5" onClick={() => {navigate("/services"); window.scrollTo(0, 0);}}>Our Services</button>
          <button className="button-5" onClick={() => {navigate("/contact"); window.scrollTo(0, 0);}}>Request an Estimate</button>
        </div>
      </div>
    </div>
     

      <div className="about-box-container">
        <div className="about-image-box">
          <img
              src={kitchen}
              alt="Kitchen"
              className="about-image"
              // width="250"
              // height="150"
          ></img>              
          </div>
      <div className="about-box">
              <h2>Build With Nebula</h2>
              <br></br>
              <p>Nebula Construction is a residential and commercial General Contractor serving the Greater Richmond, Virginia Metro Area. 
                We provide a range of services, including kitchen and bath remodels/renovations, home remodels/renovations, additions, insurance claims, and commercial upfits, renovations, and remodels. 
                Additionally, we offer punch-list services to home buyers and sellers, addressing items needed to prepare a home for the market or complete home inspection report tasks.
              </p> 
                <br></br>
              <p>With over 10 years experience, our team takes pride in delivering the highest quality throughout the construction process. 
                We're detailed-oriented, ensuring that you and our team are in full agreement of the project scope of work before construction begins.
                We maintain a high level of quality control during the construction process, communicating needs, changes, and progress to you throughout the project's duration. 
                Our commitment is to complete your project on time and within budget. 
              </p> 
                <br></br>
                <p>
                  Contact us today to schedule an appointment for our team to meet with you and discuss your project's needs.
                </p>
                <br></br>
                <div className="button-box">
                <button className="button-5" onClick={() => {navigate("/contact"); window.scrollTo(0, 0);}}>Contact Us</button>
              </div>
            </div>
            </div>
      
    </>
  );
};

export default About;