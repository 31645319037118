import React from "react";
import "../style/Portfolio.css";

const Portfolio = () => {
  

  return (
    <>
      <div>Nebula Construction</div>
      <image></image>
            <h3>Kitchen Remodeling</h3>
            <button>Read More</button> 
    </>
  );
};

export default Portfolio;
