import kitchen from '../images/Glenan-Kitchen-Best.jpg'
import balcony from '../images/Balcony-Close.jpg'
import balcony2 from '../images/Balcony.jpg'
import glenanBath from '../images/Glenan-Bath-Best.jpg'
import glenanLaundry from '../images/Glenan-Laundry.jpg'
import glenanLivingRoom from '../images/Glenan-Living-Best.jpg'
import chaseKitchen from '../images/Chase-Kitchen-Best.jpg'
import chaseBath from '../images/Chase-Bath-1.jpg'
import chaseBath2 from '../images/Chase-Bath-2.jpg'
import homeOffice from '../images/home-office.jpg'
import homeFloors from '../images/Residential-Floor.jpg'
import screendeck from '../images/Screen Deck 1.jpg'
import westlaburnroom from '../images/West-Laburn-Room.jpg'
import westlaburnkitchen1 from '../images/West-Laburn-Kitchen-1.jpg'
// import westlaburnkitchen2 from '../images/West-Laburn-Kitchen-2.jpg'


export const imagesForGallery = [
  
  {
    original: westlaburnkitchen1,
    thumbnail: westlaburnkitchen1,
    description: "Kitchen Remodel"
  },
  // {
  //   original: westlaburnkitchen2,
  //   thumbnail: westlaburnkitchen2,
  //   description: "Kitchen Remodel"
  // },
  {
    original: westlaburnroom,
    thumbnail: westlaburnroom,
    description: "Room Painting and Wood Floor Refinishing"
  },
    {
      original: kitchen,
      thumbnail: kitchen,
      description: "Full Kitchen Renovation"
    },
    {
      original: balcony2,
      thumbnail: balcony2,
      description: "Balcony Guardrail Improvement"
    },
    {
      original: balcony,
      thumbnail: balcony,
      description: "Balcony Guardrail Improvement"
    },
    {
      original: screendeck,
      thumbnail: screendeck,
      description: "Screened In Deck "
    },
    {
      original: glenanBath,
      thumbnail: glenanBath,
      description: "Full Bathroom Renovation"
    },
    {
      original: glenanLaundry,
      thumbnail: glenanLaundry,
      description: "Laundry Room Renovation"
    },
    {
      original: glenanLivingRoom,
      thumbnail: glenanLivingRoom,
      description: "Living Room Renovation"
    },
    {
      original: chaseKitchen,
      thumbnail: chaseKitchen,
      description: "Kitchen Renovation"
    },
    {
      original: chaseBath,
      thumbnail: chaseBath,
      description: "Full Bathroom Renovation"
    },
    {
      original: chaseBath2,
      thumbnail: chaseBath2,
      description: "Full Bathroom Renovation"
    },
    {
      original: homeOffice,
      thumbnail: homeOffice,
      description: "Home Office Renovation"
    },
    {
      original: homeFloors,
      thumbnail: homeFloors,
      description: "Home Renovation"
    },


  ];