import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import "../style/Header.css"
import logo from "../images/Nebula-Logo.jpg"
import { useState } from "react";

const Header = () => {


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [open, setOpen] = useState(false)

  const toggleHamburger = () => {
    setOpen(!open)
    console.log(open)
    
  }

    return(
      <>
        <div className='header'>
        <div className='logo'><Link to={"/"} className="header-links"><img src={logo} alt="Nebula Logo" 
              width="250"
              height="120"/></Link>
        </div>

        <div className="menu">

          <div className="hamburger" onClick={toggleHamburger}>
            <div className={`burger-bar burger1 ${open? 'open' : ''}`}></div>
            <div className={`burger-bar burger2 ${open? 'open' : ''}`}></div>
            <div className={`burger-bar burger3 ${open? 'open' : ''}`}></div>
          </div>

          <div className="nav-links-container">
            <div className='links'><Link to={"/"} className="header-links">Home</Link></div>
            <div className='links'><Link to={"/about"} className="header-links" >About</Link></div>
            <div className='links'><Link to={"/services"} className="header-links">Services</Link></div>
            {/* <div className='links'><Link to={"/portfolio"} className="header-links">Portfolio</Link></div>         */}
            <div className='links'><Link to={"/photo-gallery"} className="header-links">Photo Gallery</Link></div>        
            <div className='links'><Link to={"/contact"} className="header-links">Contact</Link></div>        
            <div className='request-estimate '><Link to={"/contact"} className="request-estimate2">Request an Estimate</Link></div>        
          </div>
        </div>

      
      </div>

      <div className={`nav-links-container ${open? 'active' : 'inactive'}`}>
          
            <div className='links'><Link to={"/"} className="header-links" onClick={toggleHamburger}>Home</Link></div>
            <div className='links'><Link to={"/about"} className="header-links" onClick={toggleHamburger}>About</Link></div>
            <div className='links'><Link to={"/services"} className="header-links" onClick={toggleHamburger}>Services</Link></div>
            {/* <div className='links'><Link to={"/portfolio"} className="header-links" onClick={toggleHamburger}>Portfolio</Link></div>         */}
            <div className='links'><Link to={"/photo-gallery"} className="header-links" onClick={toggleHamburger}>Photo Gallery</Link></div>        
            <div className='links'><Link to={"/contact"} className="header-links" onClick={toggleHamburger}>Contact</Link></div>   
            <div className='request-estimate'><Link to={"/contact"} className="request-estimate2" onClick={toggleHamburger}>Request an Estimate</Link></div>        
          </div>

      </>
    )

}

export default Header;

