import React, {useEffect} from "react";
import ImageGallery from "react-image-gallery"; 
import { useNavigate} from "react-router-dom";
import "../style/PhotoGallery.css";
import { imagesForGallery } from "../data/ImagesForGallery";






const PhotoGallery = () => {
  
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  return (
    <>
    <div className="top-intro-container">
        <div className="top-intro-box photo-about"></div>
        <div className="top-intro">
        <h1>Our Recent Work</h1>
            <br></br>
            <h3>Click on the bottom right of any image below to enlarge it.</h3>
            
            <div className="top-intro-button-box">
            <button className="button-5" onClick={() => {navigate("/about"); window.scrollTo(0, 0);}}>About Us</button>
            <button className="button-5" onClick={() => {navigate("/contact"); window.scrollTo(0, 0);}}>Request an Estimate</button>
            </div>
        </div>
      </div>
      
        <div className="image-gallery-wrapper">
            <ImageGallery items={imagesForGallery} showPlayButton={false} />
        </div>
    </>
  );
};

export default PhotoGallery;
